import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/layout'

const query = graphql`
  {
    allContentfulPost {
      nodes {
        title
        slug
        publishDate
      }
    }
  }
`

const PostsPage = () => {
  const data = useStaticQuery(query)
  const posts = data.allContentfulPost.nodes

  return (
    <Layout>
      <div className='container main'>
        <div className='row'>
          <div className='sixteen columns post-overview'>
            {posts?.map((post, index) => (
              <article className='post' key={index}>
                <div className='post-header'>
                  <div className='post-title'>
                    <h2 className='title-text'>
                      <a href={`/post/${post.slug}`}>{post.title}</a>
                    </h2>
                    <ul className='post-meta'>
                      <li>
                        <i className='icon-history'></i> {post.publishDate}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='post-body'>
                  <p>
                    V dokumentech jsou uloženy podklady pro členskou schůzi per rollam - písemně - podle návrhu ze dne
                    28.04.2021.
                  </p>
                  <a className='post-read' href={`/post/${post.slug}`}>
                    Čtěte dále →
                  </a>
                </div>
              </article>
            ))}

            <div className='pagination'>
              <ul>
                <li>
                  <a href='#' className='first'>
                    « První
                  </a>
                </li>

                <li>
                  <a href='#' className='previouspostslink'>
                    ‹
                  </a>
                </li>

                <li>
                  <a href='#'>
                    <span className='current'>1</span>
                  </a>
                </li>

                <li>
                  <a href='/post/list?lp-page=2&amp;category=2&amp;lang=cz' className='page'>
                    2
                  </a>
                </li>

                <li>
                  <a href='/post/list?lp-page=2&amp;category=2&amp;lang=cz' className='nextpostslink'>
                    ›
                  </a>
                </li>

                <li>
                  <a href='/post/list?lp-page=10&amp;category=2&amp;lang=cz' className='last'>
                    Poslední »
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PostsPage
